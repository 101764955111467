import { Button, Modal, theme } from "antd";
import React, { useState } from "react";
import { NotificationFilled } from "@ant-design/icons";
import { INotification } from "@/services/types";
import useUpdateNotification from "@/services/notifications/useUpdateNotification";

type Props = {
  isOpen: boolean;
  close: () => void;
  systemNotificationInfo?: Partial<INotification>;
};

function ModalSystemNotification({
  close,
  isOpen,
  systemNotificationInfo,
}: Props) {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { mutate, isPending } = useUpdateNotification();

  const handleCloseModal = () => {
    close();
    mutate({
      notificationId: systemNotificationInfo?.id as number,
      priority: 1,
    });
  };

  return (
    <Modal
      centered
      maskClosable={false}
      onCancel={close}
      title={
        <div>
          <NotificationFilled style={{ color: colorPrimary }} />
          <span className="ms-2" style={{ color: colorPrimary }}>
            {systemNotificationInfo?.title}
          </span>
        </div>
      }
      open={isOpen}
      footer={
        <Button loading={isPending} type="primary" onClick={handleCloseModal}>
          Xác nhận
        </Button>
      }>
      <p
        dangerouslySetInnerHTML={{
          __html: systemNotificationInfo?.body as any,
        }}></p>
    </Modal>
  );
}

export default ModalSystemNotification;
