import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { INotification, IPaginate } from "../types";

const useListNotificationKey = "list_notification_key";
const useListNotificationActionKey = "list_notification_action_key";
const useListNotificationSystemKey = "list_notification_system_key";
const useListNotificationModalSystemKey = "list_notification_modal_system_key";

export const useListNotification = () => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useInfiniteQuery({
    queryKey: [useListNotificationKey],
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      return lastPageParam < lastPage.data.page_count
        ? lastPageParam + 1
        : undefined;
    },
    initialPageParam: 1,
    queryFn: ({ pageParam }) => {
      return axiosInstance.get(
        `${API_ROUTES_COMPANY.notification.list}?page=${pageParam}`
      );
    },
  });
};

export const useListNotificationAction = () => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useInfiniteQuery({
    queryKey: [useListNotificationActionKey],
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      return lastPageParam < lastPage.data.page_count
        ? lastPageParam + 1
        : undefined;
    },
    initialPageParam: 1,
    queryFn: ({ pageParam }) => {
      return axiosInstance.get(
        `${API_ROUTES_COMPANY.notification.list}?page=${pageParam}&group=TASK`
      );
    },
  });
};

export const useListNotificationSystem = () => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useInfiniteQuery({
    queryKey: [useListNotificationSystemKey],
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      return lastPageParam < lastPage.data.page_count
        ? lastPageParam + 1
        : undefined;
    },
    initialPageParam: 1,
    queryFn: ({ pageParam }) => {
      return axiosInstance.get(
        `${API_ROUTES_COMPANY.notification.list}?page=${pageParam}&group=SYSTEM`
      );
    },
  });
};

export const useListNotificationModalSystem = (ACCESS_COOKIE: string) => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useQuery<AxiosResponse<IPaginate<INotification>>>({
    queryKey: [useListNotificationModalSystemKey, ACCESS_COOKIE],
    queryFn: () => {
      return axiosInstance.get(
        `${API_ROUTES_COMPANY.notification.list}?priority=0`,
        {
          headers: {
            Authorization: `token ${ACCESS_COOKIE}`,
          },
        }
      );
    },
  });
};
