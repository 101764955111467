import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useMutation } from "@tanstack/react-query";

type Params = {
  [key in string]: any;
};

const useUpdateNotification = () => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();

  return useMutation<any, any, Params>({
    mutationFn: ({ notificationId, ...rest }) => {
      return axiosInstance.put(
        API_ROUTES_COMPANY.notification.update(notificationId),
        {
          ...rest,
          is_read: true,
          is_view: true,
        }
      );
    },
  });
};

export default useUpdateNotification;
