"use client";

import React, { useContext, useEffect } from "react";
import Script from "next/script";
import {
  useMutation,
  UseMutationResult,
  useQuery,
} from "@tanstack/react-query";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";

type AccessTokenData = {
  authResponse: {
    userID: string;
    expiresIn: number;
    accessToken: string;
    signedRequest: string;
    graphDomain: string;
    grantedScopes: string;
    data_access_expiration_time: number;
  };
  status: string;
};

interface FacebookSdkContextState {
  loginMutation: UseMutationResult<AccessTokenData>;
}

interface FacebookSdkProviderProps {
  children: React.ReactNode;
}
const FacebookSdkContext = React.createContext<
  FacebookSdkContextState | undefined
>(undefined);

const FacebookSdkProvider = ({ children }: FacebookSdkProviderProps) => {
  const { FACEBOOK_APP_ID } = useEnvironment();
  const [loaded, setLoaded] = React.useState(false);
  const { mutateAsync: initialize, data: initalized } = useMutation({
    mutationFn: () => {
      return new Promise((resolve, reject) => {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: "v20.0",
          });
          resolve(true);
        };
      });
    },
  });

  const isReady = loaded && initalized === true;

  const { data: loginStatus } = useQuery({
    queryKey: ["facebook-sdk", "login-status"],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
          resolve(response);
        });
      });
    },
    enabled: isReady,
  });

  const loginMutation = useMutation<AccessTokenData>({
    mutationFn: () => {
      return new Promise((resolve, reject) => {
        window.FB.login(
          (response) => {
            resolve(response);
          },
          {
            scope: "pages_show_list,pages_messaging,pages_read_engagement",
            return_scopes: true,
          }
        );
      });
    },
  });

  useEffect(() => {
    if (loaded) {
      initialize();
    }
  }, [loaded]);

  return (
    <FacebookSdkContext.Provider
      value={{
        loginMutation,
      }}>
      <Script
        src="https://connect.facebook.net/en_US/sdk.js"
        onLoad={() => {
          console.log("Facebook SDK has loaded");
          setLoaded(true);
        }}
      />
      {children}
    </FacebookSdkContext.Provider>
  );
};

export function useFacebookSdk(): FacebookSdkContextState {
  const context = useContext<any>(FacebookSdkContext);

  if (!context) {
    throw Error("useFacebookSdk must be used within a FacebookSdkProvider");
  }
  return context;
}
export default FacebookSdkProvider;
