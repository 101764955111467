import { useAuthentication } from "@/providers/app/AuthenticationProvider";
import { useGlobalAppState } from "@/providers/app/GlobalAppStateProvider";
import { useMutation } from "@tanstack/react-query";

type Body = {
  name: string;
  registration_id: string;
  device_id: string;
  is_active?: boolean;
  type: string;
};

const useCreateDevice = () => {
  const { axiosInstance, API_ROUTES_COMPANY } = useGlobalAppState();
  const { ACCESS_COOKIE } = useAuthentication();

  return useMutation<any, any, Body>({
    mutationFn: (data) => {
      return axiosInstance.post(API_ROUTES_COMPANY.device.create, data, {
        headers: {
          Authorization: `token ${ACCESS_COOKIE}`,
        },
      });
    },
  });
};

export default useCreateDevice;
